<template>
<div class="about">
	<div class="header acea-row row-between-wrapper" ref="header">
        <div class="black" @click="returnChange()">
            <img src="@assets/images/icon_black.png" alt="" />
        </div>
        <p>关于我们</p>
    </div>
    <div class="about-content">
    	<div class="about-us">
			<img src="@assets/images/icon_logo.png" alt="" />
	    </div>
	    <div class="artile">
		    <p>{{aboutData.intr}}</p>
	    </div>
    </div>
    <div class="notice-container acea-row row-between">
        <div class="left acea-row row-left" @click="callPhone(phone.site_phone)">
          <div class="img">
            <img src="@assets/images/icon_tel.png" alt="" />
          </div>
          <div class="cont">
            <p>咨询热线</p>
            <p class="tel">{{phone.site_phone}}</p>
          </div>
        </div>
        <div class="left acea-row row-left" @click="getServiceAuto()">
          <!-- <router-link :to="{path:'/customer/list'}" class="acea-row row-left"> -->
            <div class="img">
              <img src="@assets/images/icon_kefu.png" alt="" />
            </div>
            <div class="cont">
              <p>在线客服</p>
              <p class="tel">工作时间9:00-18:00</p>
            </div>
          <!-- </router-link> -->
        </div>
    </div>
</div>
</template>

<script>
import { getAbout,serviceAuto } from "@api/user";
import cookie from "@utils/store/cookie";
export default {

	name:'aboutUs',
	data(){
		return{
            aboutData:'',
            phone:'',
		}
	},
	careated(){

	},
	mounted(){
		this.phone = cookie.get('phone');
         this.getAboutUsData();
	},
	methods:{
    //进入客服
    getServiceAuto() {
      serviceAuto().then(res => {
        console.log(res);
        location.href ="https://work.weixin.qq.com/kfid/kfce5fb4acd32b5c3d5";

        // this.$router.push(
        //   '/customer/chat/' +
        //     res.data.to_uid
        //     // +
        //     // '/' +
        //     // productId +
        //     // (orderId ? '?orderId=' + orderId : '')
        // )
      });
    },
		//获取数据
		getAboutUsData(){
            getAbout().then(res=>{
                this.aboutData = res.data;
            }).catch(res=>{
            	console.log(res);
            })

		},
		//拨打电话
        callPhone:function (phoneNumber) {
            window.location.href = 'tel://' + phoneNumber
        },
		//返回上一页
        returnChange() {
            var that = this;
            that.$router.go(-1);
        },
	}
}
</script>

<style>
.about { padding-top:50px; overflow:hidden; background:#fff; min-height:100% }

.about .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
}
.about .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
}
.about .header .black img {
  width: 100%;
  height: 100%;
}
.about .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
}
</style>


